<template>
  <div>
    <van-list>
      <ul class="h-list">
        <li class="item" v-for="item in unwrapList" :key="item.objectId">
            <h5>{{item.type}}
              <span class="small-text">{{item.createdAt}}</span>
              </h5>
            <p>{{item.startTime}}-{{item.endTime}}</p>
        </li>
      </ul>
    </van-list>
  </div>
</template>
<script>
import dayjs from 'dayjs'

export default {
  data () {
    return {
      list: []
    }
  },
  computed: {
    unwrapList () {
      return this.list.map(item => {
        return {
          ...item.attributes,
          createdAt: dayjs(item.createdAt).format('YYYY-MM-DD')
        }
      })
    }
  },
  mounted () {
    const query = new this.AV.Query('Todo')
    query.descending('createdAt')
    query.find().then(res => {
      console.log(res)
      this.list = res
    })
  }
}
</script>
<style lang='scss' scoped>
.h-list {
  .item {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    background-color: #fff;
    border-bottom: 1px solid #ebedf0;
    h5 {
      font-weight: 600;
      font-size: 18px;
    }
    .small-text {
      color: #9fafc1;
      font-size: 12px;
    }
  }
}
</style>
